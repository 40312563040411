import React from "react";

const Footer = () => {
  return (
    <>
      <footer id="footer" class="footer">
        <div class="container">
          <div class="copyright">
            <div class="social-links mt-3">
              <a
                href="https://twitter.com/muitindia"
                style={{
                  color: "hsl(353, 96%, 18%)",
                  fontWeight: "400",
                  fontSize: "14px",
                  marginRight: "5px",
                }}
                aria-label="Twitter (opens in a new tab)"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i>
                  <img
                    src="assets/img/Twitter-Logо.png"
                    style={{ width: "20px" }}
                  />
                </i>
              </a>
              <a
                href="https://www.facebook.com/muitindia/"
                style={{
                  color: "#fff",
                  fontWeight: "400",
                  fontSize: "14px",
                  marginRight: "5px",
                }}
                aria-label="Facebook (opens in a new tab)"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i class="fa5 bi bi-facebook" aria-hidden="true" role="img"></i>
              </a>
              <a
                href="https://in.pinterest.com/muit_india/"
                style={{
                  color: "#fff",
                  fontWeight: "400",
                  fontSize: "14px",
                  marginRight: "5px",
                }}
                aria-label="Pinterest (opens in a new tab)"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i
                  class="fa5 bi bi-pinterest"
                  aria-hidden="true"
                  role="img"
                ></i>
              </a>
              <a
                href="https://www.instagram.com/muitindia/"
                style={{
                  color: "#fff",
                  fontWeight: "400",
                  fontSize: "14px",
                  marginRight: "5px",
                }}
                aria-label="Instagram (opens in a new tab)"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i
                  class="fa5 bi bi-instagram"
                  aria-hidden="true"
                  role="img"
                ></i>
              </a>
              <a
                href="https://in.linkedin.com/company/muitindia"
                style={{
                  color: "#fff",
                  fontWeight: "400",
                  fontSize: "14px",
                  marginRight: "5px",
                }}
                aria-label="LinkedIn (opens in a new tab)"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i class="fa5 bi bi-linkedin" aria-hidden="true" role="img"></i>
              </a>
              <a
                href="https://www.tumblr.com/muitindia"
                style={{
                  color: "#fff",
                  fontWeight: "400",
                  fontSize: "14px",
                  marginRight: "5px",
                }}
                aria-label="Tumblr (opens in a new tab)"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="assets/img/tumbler.png" style={{ width: "13px" }} />
              </a>
              <a
                href="https://www.youtube.com/channel/UCVfCiHFYewwHRGta5YI1bYQ"
                style={{
                  color: "#fff",
                  fontWeight: "400",
                  fontSize: "14px",
                  marginRight: "5px",
                }}
                aria-label="Youtube (opens in a new tab)"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i class="fa5 bi bi-youtube" aria-hidden="true" role="img"></i>
              </a>
              <a
                href="https://www.kooapp.com/profile/muitindia"
                style={{
                  color: "#fff",
                  fontWeight: "400",
                  fontSize: "14px",
                  marginRight: "5px",
                }}
                aria-label="Koo (opens in a new tab)"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i>
                  <img src="assets/img/kooapp1.png" style={{ width: "18px" }} />
                </i>
              </a>
              <a
                href="https://t.me/maharishiuniversity"
                style={{
                  color: "#fff",
                  fontWeight: "400",
                  fontSize: "14px",
                  marginRight: "5px",
                }}
                aria-label="Koo (opens in a new tab)"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i class="fa5 bi bi-telegram" aria-hidden="true" role="img"></i>
              </a>
              <a
                href="https://en.wikipedia.org/wiki/Maharishi_University_Of_Information_Technology"
                style={{
                  color: "#fff",
                  fontWeight: "400",
                  fontSize: "14px",
                  marginRight: "5px",
                }}
                aria-label="Koo (opens in a new tab)"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i
                  class="fa5 bi bi-wikipedia"
                  aria-hidden="true"
                  role="img"
                ></i>
              </a>
              <a
                href="https://www.threads.net/muitindia"
                style={{
                  color: "#c01a1a",
                  fontWeight: "400",
                  fontSize: "14px",
                  marginRight: "5px",
                }}
                aria-label="Threads (opens in a new tab)"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i class=" fa5 bi bi-threads" aria-hidden="true" role="img"></i>
              </a>
            </div>
            All Rights Reserved | Maharishi University of Informaton Technology
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
