import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './App.css';
import Banner from './Components/Banner';
import Content from "./Components/Content";
import Footer from "./Components/Footer";
import MAP from "./Components/MAP";
function App() {
  return (
    <>
      <Router>
        <Routes>
<Route  exact path="/" element={ <> <Banner/><Content/><MAP/><Footer/></>}>

</Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
