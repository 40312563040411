import React from 'react'

const Content = () => {
  return (
    <>
     <section id="hero" className="hero team d-flex align-items-center">
    <div className="container">
    <div className="row gy-4">

      <div className="col-lg-6 col-md-6 d-flex align-items-stretch aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
        <a href="https://maharishiuniversity.ac.in/" target="_blank" >
        <div className="member">
          <div className="member-img">
           
               <img src="assets/img/lucknow.jpg" className="img-fluid" alt=""/>
           
          </div>
          <div className="centered"> 
            <h4>Visit Lucknow Campus Website</h4>
            </div>
           
        </div>
      </a>
      </div>
      <div className="col-lg-6 col-md-6 d-flex align-items-stretch aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
        <a href="https://muitnoida.edu.in/" target="_blank" style={{zIndex: '199999'}}>
           <div className="member">
          <div className="member-img">
            
               <img src="assets/img/muit-noida.png" className="img-fluid" alt=""/>
          
          </div>
         
          <div className="centered"> 
           <h4>Visit Noida Campus Website</h4>
            </div>
           
        </div>
      </a>
       
      </div>

      <div className="col-lg-12 d-flex flex-column justify-content-center">
        <p>&nbsp;</p>
       
        <h1 data-aos="fade-up" className="aos-init aos-animate">About Maharishi University</h1>
        <p data-aos="fade-up" style={{textAlign: 'justify'}} data-aos-delay="400" className="aos-init aos-animate"> <br/>
        The Maharishi University of Information Technology (MUIT) was established under MUIT Uttar Pradesh Act No. 31 of 2001 Notification No. 573 dated 06 October, 2001. Maharishi University has developed phenomenally since its inception to be recognized as a premier University in the country today. It offers Bachelor, Master and Doctoral programmes in one of the widest range of disciplines, including Science, Commerce, Management, Humanities, Pharmacy, Engineering, Animation, Journalism & Mass Communication, Law, and Enrichment Courses. Our goal for every student is enlightenment — the systematic development of full potential from within. This is the key to true fulfillment in daily life and any career.
       </p>
       
      </div>
     
    </div>
     

      

    </div>




   

  </section>
    </>
  )
}

export default Content